//Primary Color State's
$primaryColor: #ed9666;
$primaryHoverColor: #d3550e;
$primaryPressColor: #ae460c;

//Secondary Color State's
$secondaryColor: #005172;
$secondaryHoverColor: $secondaryColor;
$secondaryPressColor: #01384f;
$secondaryAlternativeHoverBackgroundColor: #196280;

//Secondary Alternative Color State's
$secondaryAlternativeColor: #1e1e1e;

// Other Color
$greyColor: #747f81;
$greyDarkColor: #737373;
$lightGreyColor: #ededed;

$lightBlueColor: #ebf5ff;
$lightBlueHoverColor: #f5f5f5;

$blackColor: #2f2f2f;

$submenuHighlightedColor: #fdf4f0;

$secondaryOutlineAlternativeHoverBackgroundColor: #353535;

// Languague Bar
$levelOneBackgroundColor: #ccdff2;
$levelTwoBackgroundColor: #a3c3e2;
$levelThreeBackgroundColor: #4c79a7;

// Footer
$footerBackgroundColor: #1e1e1e;
$footerCopyrightBackgroundColor: #2d2d30;

// Alerts
$successColor: #009262;
$lightSuccessColor: #f1fbf8;

$infoColor: #1b8ac9;
$lightInfoColor: #ebf5ff;

$warningColor: #e48900;
$lightWarningColor: #fff8ec;

$dangerColor: #c33025;
$lightDangerColor: #fff1f0;

// Variables for Autocomplete ( REVIEW WITH DESIGN)

$primaryTextColor: #00395b;


$primaryBackgroundColor: #EBF5FF;

$inputPlaceholderTextColor: #bcd1df;

$inputDisabledTextColor: #c1c1c1;

$inputInvalidTextColor: #f96f6f;
$inputInvalidBorderColor: #f96f6f;
$inputInvalidBackgroundColor: #ffe9e9;

//Link Color State's
$linkColor: $secondaryColor;
$linkHoverColor: #065288;

//Input
$inputTextColor: $primaryTextColor;
$inputPlaceholderTextColor: #bcd1df;
$inputBackgroundColor: $primaryBackgroundColor;

$inputBorderColor: #005172;
$inputBorderHoverColor: $inputPlaceholderTextColor;
$inputBorderFocus: $inputPlaceholderTextColor;

$inputWarningBorderColor: #ffc676;

$inputInvalidTextColor: #f96f6f;
$inputInvalidBorderColor: #f96f6f;
$inputInvalidBackgroundColor: #ffe9e9;

$inputDisabledTextColor: #c1c1c1;
$inputDisabledBorderColor: #e7e7e7;
$inputDisabledBackgroundColor: #fbfbfb;

$inputDisabledTextColorSecondary: #A6C7D5;
$inputDisabledBorderColorSecondary: #A6C7D5;
$inputDisabledBackgroundColorSecondary: #F5F5F5;

//generic
$white100: #ffffff;


$lightGreyBorderColor: #E5E7EB;

// stripe payment input
$stripeInputBorderColor: #d5e6f2;
$stripePrimaryBackgroundColor: #f0f6fc;
