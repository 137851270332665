@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');
@import 'src/assets/mixins/theme/material-theme.scss';

// REMOVE THIS
@import 'src/assets/variables/font-sizes.scss';
@import 'src/assets/variables/colors.scss';


html {
    box-sizing: border-box;
    overflow-y: scroll;
    min-height: 100vh;
    scroll-behavior: smooth;

    &.no-scroll {
        overflow-y: hidden;
    }
}

body {
    font-family: 'Roboto Flex', sans-serif;
    margin: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #000000;
    min-height: 100vh;
}

app-root {
    display: block;
    min-height: 100vh;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

a,
a:visited,
a:hover {
    text-decoration: inherit;
    transition: all 0.2s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0 0 1.25rem 0;
}

main {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.sr-only {
    left: -200vw;
    position: absolute;
}

body .grecaptcha-badge {
    display: none !important;
}
  
body.show-recaptcha .grecaptcha-badge {
    display: block !important;
    visibility: visible !important;
}