@import '~@angular/material/theming';
@import 'src/assets/variables/colors.scss';

$primary-color: mat-palette($mat-indigo);
$accent-color: mat-palette($mat-pink);
$warn-color: mat-palette($mat-red);
$theme: mat-light-theme((
  color: (
    primary: $primary-color,
    accent: $accent-color,
    warn: $warn-color,
  ),
));

// Include Angular Material core styles with your custom theme
@include mat-core();
@include angular-material-theme($theme);

// Custom Snackbar styles
.mat-snack-bar-container {
  background: $secondaryColor;
  color: #fff; 
  font-size: 16px;
  border-radius: 8px;

  .mat-button-wrapper {
    color: #fff;
  }
}